#root {
  height: 100%;
}

.new-event.content {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  padding-left: 0;
  padding-right: 0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 80px;
}

.drag-handle {
  cursor: move !important;
  /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab !important;
  cursor: -webkit-grab !important;
}

/* css for custom */
.mb-40 {
  margin-bottom: 40px !important;
}

.text-center {
  text-align: center !important;
}

.p-6 {
  padding: 6px !important;
}

.lobbyButton {
  background-color: #fff !important;
  color: rgba(0,0,0,.54) !important;
  height: 50px;
  width: 100%;
  border: none !important;
  text-align: left;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 25%) !important;
  font-size: 16px !important;
  line-height: 48px !important;
  border-radius: 1px !important;
  text-transform: inherit !important;
  padding: 0 !important;
  display: block !important;
}

.lobbyButton:hover {
  background-color: #fff !important;
  box-shadow: rgb(28 71 98 / 50%) 0px 0px 3px 3px !important;
}

.lobbyButton:focus {
  outline: -webkit-focus-ring-color auto 1px;
}

/* 1. eventList */
.eventList .MuiCard-root {
  overflow: inherit;
}

.eventList .cardBtn {
  font-size: .9rem;
  font-weight: 600;
  border-radius: 4px;
  color: #da2926;
}

.eventList .date {
  width: 45px;
  position: absolute;
  left: 16px;
  top: 0;
  padding: 8px 0;
  text-align: center;
  background: #000;
  z-index: 1;
  transform: translate(0, -50%);
}

.eventList .date .middle {
  display: table;
  width: 100%;
  height: 100%;
}

.eventList .date .middle .inner-middle {
  display: table-cell;
  vertical-align: middle;
}

.eventList .date span {
  font-size: 20px;
  line-height: initial;
  font-weight: 600;
  color: #fff;
}

.eventList .date p {
  margin: 0;
  font-size: 12px;
  text-transform: uppercase;
  line-height: initial;
  color: #fff;
}

.eventList .media {
  position: relative;
  height: 160px;
}

.eventList h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  max-height: 56px;
}

.eventList p {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  max-height: 60px;
  font-size: 14px;
}

/* 2. profile-card */
.profile-card {
  position: relative;
  overflow: hidden;
  width: 100%;
  text-align: center;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  border-radius: 7px;
  margin-bottom: 20px;
}

.profile-card .background-block {
  float: left;
  width: 100%;
  height: 200px;
  overflow: hidden;
}

.profile-card .background-block span {
  position: absolute;
  z-index: 1;
  color: #fff;
  font-size: 24px;
  font-weight: 800;
  left: 50%;
  transform: translate(-50%);
  top: 20px;
}

.profile-card .background-block .background {
  width: 100%;
  vertical-align: top;
  -webkit-transform: scale(4.2);
  transform: scale(4.2);
}

.profile-card .card-content {
  width: 100%;
  padding: 15px 25px 25px 25px;
  color: #232323;
  float: left;
  background: #fff;
  height: 50%;
  border-radius: 0 0 5px 5px;
  position: relative;
  z-index: 1;
}

.profile-card .card-content::before {
  content: '';
  background: #fff;
  width: 120%;
  height: 100%;
  left: 11px;
  bottom: 51px;
  position: absolute;
  z-index: -1;
  transform: rotate(-13deg);
}

.profile-card .profile {
  border-radius: 50%;
  position: absolute;
  bottom: 50%;
  left: 50%;
  width: 100px;
  height: 100px;
  object-fit: cover;
  opacity: 1;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.5);
  border: 2px solid rgba(255, 255, 255, 1);
  -webkit-transform: translate(-50%, 0%);
  transform: translate(-50%, 0%);
  z-index: 2;
}

.profile-card h2 {
  margin: 0 0 5px;
  font-weight: 600;
  font-size: 25px;
  text-transform: capitalize;
}

.profile-card h2 small {
  display: block;
  font-size: 15px;
  margin-top: 10px;
}

.profile-card .icon-block a {
  display: inline-block;
  text-align: center;
  border: 1px solid #232323;
  width: 30px;
  height: 30px;
  line-height: 35px;
  border-radius: 50%;
  margin: 0 5px;
}

.profile-card svg {
  color: #232323;
  width: 16px;
  height: 16px;
}

.profile-card a:hover {
  background-color: #232323;
}

.profile-card a:hover svg {
  color: #fff;
}

.profile-card .icon-block {
  float: left;
  width: 100%;
  margin-top: 15px;
}

.profile-card .icon-block img {
  max-width: 200px;
  margin: auto;
}

/* 3. timeline */
.timeline {
  padding: 0 !important;
}

.timeline li:before {
  display: none;
}

.timeline .timelineDot {
  padding: 0;
}

.timeline .timelineItem:last-child .timelineContent {
  margin-bottom: 0;
}

.timeline .timelineContent {
  margin-bottom: 16px;
  padding: 8px 16px 8px 16px;
  box-shadow: 0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12);
  background-color: #fff;
  margin-left: 16px;
  border-radius: 7px;
  margin-top: 8px;
}

.timeline .current .timelineDot {
  border-color: #da2926;
}

.timeline .current .timelineContent {
  background-color: #da2926;
  color: #fff;
}

.timeline .current .timelineContent p {
  color: #fff !important;
}

.timeline .current .timelineConnector {
  background-color: #da2926;
}

/* 4. conferenceBanner */
.conferenceBanner {
  position: absolute;
  margin: 0 !important;
  bottom: 0;
  height: 120px;
  left: 0;
  right: 0;
  width: 100% !important;
  background-color: #000;
  padding: 6px;
}

.conferenceBanner .banner {
  height: 100%;
  position: relative;
}

.conferenceBanner .banner p {
  margin: 0 0 6px 0;
  color: #fff;
  font-size: 14px;
}

.conferenceBanner .banner .banner-item {
  width: 100%;
  height: 70px;
  position: relative;
  background-repeat: no-repeat;
  background-size: cover;
}

.pulse {
  display: inline-block;
  width: 9px;
  height: 10px;
  border-radius: 50%;
  background: #da2926;
  cursor: pointer;
  box-shadow: 0 0 0 rgb(218 41 38 / 0.4);
  animation: pulse 2s infinite;
  vertical-align: initial;
}
.pulse:hover {
  animation: none;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgb(218 41 38 / 0.4);
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgb(226 224 215 / 0%);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgb(226 224 215 / 0%);
  }
}
@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgb(218 41 38 / 0.4);
    box-shadow: 0 0 0 0 rgb(218 41 38 / 0.4);
  }
  70% {
      -moz-box-shadow: 0 0 0 10px rgb(226 224 215 / 0%);
      box-shadow: 0 0 0 10px rgb(226 224 215 / 0%);
  }
  100% {
      -moz-box-shadow: 0 0 0 0 rgb(226 224 215 / 0%);
      box-shadow: 0 0 0 0 rgb(226 224 215 / 0%);
  }
}

@media (max-width: 600px) {
  .conferenceBanner {
    display: none !important;
  }
}

/* 5. loginLoader */
.loginLoader h6 {
  margin-top: 15px;
}

/* 6. boothGrid */
.boothGrid .media {
  position: relative;
  height: 160px;
}

.boothGrid h2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 56px;
}

/* 7. horizontalAdds */
.horizontalAdds .slick-slider {
  display: grid !important;
}

.horizontalAdds .slick-list {
  padding: 0 !important;
}

.horizontalAdds .slider-item {
  height: 70px;
}

.horizontalAdds .slider-item a {
  outline: none;
}

.horizontalAdds .slider-item img {
  height: 100%;
}

/* 8. verticalAdds */
.verticalAdds .slick-list {
  padding: 0 !important;
}

.verticalAdds .slider-item a {
  outline: none;
}

.verticalAdds .slider-item img {
  width: 100%;
  height: 100%;
  border-radius: 7px;
  box-shadow: 0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%);
}
